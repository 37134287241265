import React from 'react';
import styled from 'styled-components';

import ContainerDefault from '../container';

const Container = styled.div`
  background: #000;

  .wrapper {
    height: 120px;
  }

  @media (max-width: 1200px) {
    .wrapper {
      height: auto;
      padding: 21px 0 32px;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 768px) {
    .wrapper {
      padding: 32px 0;
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font: italic bold 24px 'Times new roman';
      color: #fff;
      max-width: 280px;

      @media (max-width: 1200px) {
        max-width: inherit;
        width: 100%;
        margin-bottom: 20px;
      }

    }
    input {
      border: 0;
      border-bottom: 2px solid #464646;
      color: #fff;
      height: 48px;
      background: 0;
      max-width: 280px;
      font-size: 16px;
      width: 100%;
      transition: all 0.3s;
      &:focus {
        border-color: #C7C7C7;
      }
      @media (max-width: 1200px) {
        max-width: 48%;
      }
      @media (max-width: 768px) {
        max-width: 100%;
        margin-bottom: 10px;
      }
    }
    :-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::placeholder {
      color: #fff;
      opacity: 1;
    }
    button {
      cursor: pointer;
      max-width: 180px;
      width: 100%;
      background: #484848;
      display: flex;
      border-radius: 3px;
      border: 0;
      color: #fff;
      height: 48px;
      align-items: center;
      font-size: 14px;
      padding: 0;
      transition: all 0.3s;
      &:hover {
        background: #797979;
        span {
          border-color: #5E5E5E;
        }
      }
      @media (max-width: 1200px) {
        margin: 32px auto 0;
      }
      @media (max-width: 768px) {
        margin: 20px auto 0;
      }
      .btn-text {
        height: 100%;
        flex-grow: 1;
        border-right: 1px solid #3B3B3B;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .icon {
        display: flex;
        align-items: center;
        min-width: 46px;
        justify-content: center;
        i {
          width: 15px;
          background: url(/images/arrow-right.png) center no-repeat;
          height: 15px;
          background-size: contain;
        }
      }
    }
  }
  input::placeholder {
    color: #fff;
    opacity: 1;
  }
`
const Text = styled.div`
  .wrapper {
    width: 100%;
    padding: 40px 20px 55px;
    margin: 0 auto;
    font: 14px;
    color: #808080;

    @media (min-width: 767px) {
      max-width: 767px;
    }

    @media (min-width: 1200px) {
      max-width: 1200px;
      padding: 40px 15px;
    }
  }
`

export default function Newsletter() {
  return (
    <>
      <Text>
        <div className="wrapper">
          O Meu Ambiente Guararapes é um espaço voltado para dar visibilidade a profissionais e projetos de interiores. A Guararapes não tem vínculos com os profissionais cadastrados e não se responsabiliza pela garantia dos serviços acordados com terceiros. A responsabilidade sob a veracidade das informações publicadas é do autor da publicação. <a href="https://www.guararapes.com.br/conteudo/5-passos-para-contratar-profissionais-de-arquitetura" target="_blank">Clique aqui</a> e veja dicas de como escolher o profissional certo para seu projeto.
        </div>
      </Text>
      <Container>
        <ContainerDefault>
          <div className="wrapper">
            <span className="text">Fique por dentro de todas as novidades da Guararapes</span>
            <input type="text" placeholder="Preencha seu nome" />
            <input type="email" placeholder="Preencha seu email" />
            <button>
              <span className="btn-text">Cadastrar</span>
              <span className="icon">
                <i></i>
              </span>
            </button>
          </div>
        </ContainerDefault>
      </Container>
    </>
  )
}