import React, { useContext } from "react";
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';

import { AuthContext } from '../../context/auth';

import ContainerDefault from '../container';

const Container = styled.div`
  display: none;
  background: #000000;
  padding: 8px 0;
  
  @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;500;700&display=swap');

  div.top-bar-menu {
    float: left;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    opacity: .8;
    height: 35px;
    margin-top: -8px;
  }

  div.top-bar-menu a {
    display: inline-block;
    padding: 13px 15px;
    color: #fff;
    position:relative;
  }

  div.top-bar-menu a.selected {
    opacity: 1;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #b78d83;
    background: #272724;
  }

  div.top-bar-menu a.first:after {
    position: absolute;
    right: 0px;
    top: 11px;
    display: block;
    content: "";
    opacity: .8;
    width: 1px;
    height: 16px;
    background-color: #ecaeae;
  }


  div.top-bar-menu a span {
    font-family:'Ubuntu', sans-serif;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      margin-left: 70px;

      a {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #ffffff;
        transition: .2s all ease;

        i {
          margin-right: 10px;
          width: 24px;
          height: 24px;

          &.face-icon {
            background: url('/images/streamline-icon-single-neutral-actions-add@140x140@2x.png') no-repeat center;
            background-size: contain;
          }
          &.go-icon {
            background: url('/images/streamline-icon-logout-140-x-140-1@2x.png') no-repeat center;
            background-size: contain;
          }
        }

        &:hover {
          color: #AE887E;
        }
      }

      > button {
        font-size: 12px;
        color: #ffffff;
        cursor: pointer;
        background: transparent;
      }
    }
  }

  @media (min-width: 767px) {
    display: block;
  }

  @media (min-width: 1200px) {
    ul li {
      margin-left: 35px;

      a {
        font-size: 14px;
      }
    }
  }
`;

export default function TopBar() {
  const { isLoggedIn, singOut } = useContext(AuthContext);

  function handleLogout() {
    singOut(() => window.location = window.location.origin);
  }

  return (
    <Container>
      <ContainerDefault>
        
        <div class="top-bar-menu">
          <a target="_blank" href="https://www.guararapes.com.br" class="first" rel="noopener">
            <span class="">Institucional</span>
          </a>
          <a target="_blank" href="https://casa.guararapes.com.br/" class="">
            <span class="">Casa Guararapes</span>
          </a>
          <a href="/" class="selected" rel="noopener">
            <span class="">Meu Ambiente Guararapes</span>
          </a>
          <a target="_blank" href="https://www.guararapes.com.br/conteudos/blog" class="" rel="noopener">
           <span class="">Blog</span>
          </a>
        </div>        
        
        <ul>
          <li>
            {isLoggedIn ? (
              <button type="button" onClick={handleLogout} onKeyDown={handleLogout}>Logout</button>
            ) : (
              <Link to="/registrar" >
                <i className="face-icon"></i>
                <span>Ainda não tem cadastro? Crie aqui.</span>
              </Link>
            )}
          </li>
        </ul>
      </ContainerDefault>
    </Container>
  );
}
