import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import ContainerDefault from '../container';

const Container = styled.div`
  padding: 35px 0 25px;
  .wrapper {
    justify-content: space-between;
    align-items: flex-start;

    &.flex {
      display: flex;
      height: 100%;
    }

    a {
      text-decoration: none;
    }
    div {
      width: 25%;
    }
    @media (max-width: 1200px) {
      .contact-column {
        display: none;
      }
      .contact {
        display: block;
        width: 100%;
        margin-bottom: 60px;
      }
    }
  }
  .last-column span {
    margin-bottom: 25px;
  }
  .contact,
  .contact-column {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 1200px) {
    .contact-column {
      display: block;
    }
  }
`
const Title = styled.span`
  color: #D2AE82;
  font: italic bold 24px 'Times New Roman';
  display: block;
  margin-bottom: 11px;
  transition: all 0.3s;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
  @media (max-width: 1200px) {
    font: italic bold 20px 'Times New Roman';
  }
`
const List = styled.ul`
  margin: 18px 0 0;
  padding: 0;
  li {
    margin-bottom: 18px;
    display: block;
    span,
    a {
      color: #808080;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      transition: all 0.3s;
      &:hover {
        color: #fff;
      }
      &.regular {
        font-weight: normal;
      }
      @media (max-width: 1200px) {
        font-size: 14px;
      }
    }
  }
`
const SubList = styled.ul`
  margin: 8px 0 0 20px;
  padding: 0;
  li {
    margin-bottom: 13px;
    display: block;
    a {
      font-weight: 400;
    }
  }
`;

export default function Menu() {
  return (
    <Container>
      <ContainerDefault>
        <div className="wrapper flex">
          <div>
            <Link to="https://guararapes.com.br/a-guararapes">
              <Title>A Guararapes</Title>
            </Link>
            <List>
              <li>
                <Link to="https://www.guararapes.com.br/a-guararapes#quem-somos">Quem somos</Link>
              </li>
              <li>
                <Link to="https://www.guararapes.com.br/a-guararapes#historia">História</Link>
              </li>
              <li>
                <Link to="https://www.guararapes.com.br/a-guararapes#manifesto">Manifesto</Link>
              </li>
              <li>
                <Link to="https://www.guararapes.com.br/a-guararapes#unidades">Unidades</Link>
              </li>
              <li>
                <Link to="https://www.guararapes.com.br/a-guararapes#responsabilidade-ambiental-social">Responsabilidade Ambiental e Social</Link>
              </li>
            </List>
          </div>
          <div>
            <Link to="https://guararapes.com.br/produtos">
              <Title>Produtos</Title>
            </Link>
            <List>
              <li>
                <Link to="https://guararapes.com.br/produtos/mdf">
                  <span>MDF</span>
                </Link>
                <SubList>
                  <li>
                    <Link to="https://guararapes.com.br/produtos/mdf/cores-basicas">Cores básicas</Link>
                  </li>
                  <li>
                    <Link to="https://guararapes.com.br/produtos/mdf/decorativos">Decorativos</Link>
                  </li>
                  <li>
                    <Link to="https://guararapes.com.br/produtos/mdf/mdf-cru">MDF Cru</Link>
                  </li>
                  <li>
                    <Link to="https://guararapes.com.br/certificados/mdf">Certificações</Link>
                  </li>  
                </SubList>
              </li>
              <li>
                <Link to="https://guararapes.com.br/produtos/compensados">
                  <span>Compensados</span>
                </Link>
                <SubList>
                  <li>
                    <Link to="https://guararapes.com.br/produtos/compensados/estruturais">Estruturais</Link>
                  </li>
                  <li>
                    <Link to="https://guararapes.com.br/produtos/compensados/nao-estruturais">Não Estruturais</Link>
                  </li>
                  <li>
                    <Link to="https://guararapes.com.br/certificados/compensados">Certificações</Link>
                  </li>    
                </SubList>
              </li>
            </List>
          </div>
          <div>
            <Link to="https://guararapes.com.br/conteudos">
              <Title>Conteúdos</Title>
            </Link>
            <List>
              <li>
                <Link to="https://www.guararapes.com.br/conteudos/blog">Blog</Link>
              </li>
              <li>
                <Link to="https://www.guararapes.com.br/conteudos/videos">Vídeos</Link>
              </li>
              <li>
                <Link to="https://www.guararapes.com.br/conteudos/aprenda-mais">Aprenda mais</Link>
              </li>
            </List>
          </div>
          <div className="contact-column">
            <Link to="https://guararapes.com.br/contato">
              <Title>Contato</Title>
            </Link>
            <List>
              <li>
                <Link to="https://www.guararapes.com.br/contato">Dúvidas frequentes</Link>
              </li>
              <li>
                <Link to="https://www.guararapes.com.br/contato/trabalhe-conosco">Trabalhe conosco</Link>
              </li>
              <li>
                <a href="https://etica.guararapes.com.br" target="_blank" rel="noreferrer">Comitê de Ética</a>
              </li>
            </List>
          </div>
          <div className="last-column">
            <Link to="https://www.guararapes.com.br/onde-encontrar">
              <Title>Onde Encontrar</Title>
            </Link>
            <Link to="https://casa.guararapes.com.br/">
              <Title>Casa Guararapes</Title>
            </Link>
          </div>
        </div>
      </ContainerDefault>
    </Container>
  );
}