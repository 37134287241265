import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 286px;
  align-items: center;
  a {
    margin-left: 20px;
    i {
      height: 46px;
      width: 46px;
      display: flex;
    }
    @media (max-width: 767px) {
      margin: 0 10px;
    }
  }
`
const FacebookIcon = styled.i`
  background: url(/images/fb.svg) no-repeat center;
  background-size: contain;
  &:hover {
    background-image: url(/images/icon-facebook-hover.png);
  }
`
const InstagramIcon = styled.i`
  background: url(/images/insta.svg) no-repeat center;
  background-size: contain;
  &:hover {
    background-image: url(/images/icon-instagram-hover.png);
  }
`
const PinterestIcon = styled.i`
  background: url(/images/Pinterest.svg) no-repeat center;
  background-size: contain;
  &:hover {
    background-image: url(/images/icon-pinterest-hover.png);
  }
`
const YoutubetIcon = styled.i`
  background: url(/images/youtube.svg) no-repeat center;
  background-size: contain;
  &:hover {
    background-image: url(/images/icon-youtube-hover.png);
  }
`

export default function Social() {
  return (
    <Container>
      <a href="https://pt-br.facebook.com/Guararapes/" target="_blank" rel="noreferrer">
        <FacebookIcon />
      </a>
      <a href="https://www.instagram.com/guararapes/" target="_blank" rel="noreferrer">
        <InstagramIcon />
      </a>
      <a href="https://br.pinterest.com/GuararapesMDF/" target="_blank" rel="noreferrer">
        <PinterestIcon />
      </a>
      <a href="https://www.youtube.com/channel/UCElUW8GOzfryq7KlxW6Lx-g" target="_blank" rel="noreferrer">
        <YoutubetIcon />
      </a>
    </Container>
  )
}