import React from 'react';
import styled from 'styled-components';

import TopBar from './top-bar';
import Menu from './menu';

const Container = styled.div`
  background: #2D2D2C;
`

const Footer = () => {
  return (
    <Container>
      <TopBar />
      <Menu />
    </Container>
  )
}

export default Footer;
