import React, { useContext, useEffect } from "react";

import { AuthContext } from "../../context/auth";

import Menu from './menu';
import TopBar from './top-bar';

export default function Header(props) {
  const { getUser, handleIsLoggedIn, isLoggedIn } = useContext(AuthContext);

  function checkAuth() {
    if (isLoggedIn)
      return false;
    if (getUser && getUser.id) {
      handleIsLoggedIn(true);
    } else {
      handleIsLoggedIn(false);
    }
    return false;
  }


  useEffect(() => {
    checkAuth();
  }, [getUser]);
  
  return (
    <>
      <TopBar />
      <Menu />
    </>
  );
}
