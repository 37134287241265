import React from 'react';
import styled from 'styled-components';

const GlobalContainer = styled.section`
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;

  @media (min-width: 767px) {
    max-width: 767px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
    padding: 0 15px;
  }
`;

export default function ContainerDefault({ children }) {
  return (
    <GlobalContainer>
      {children}
    </GlobalContainer>
  )
}