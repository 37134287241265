import React, { useEffect, useState, useContext } from "react";
import { Link } from 'gatsby';
import { navigate } from "@reach/router";
import styled from 'styled-components';

import { AuthContext } from '../../context/auth';

import ContainerDefault from '../container';

const Container = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  background: #2D2D2C;
  padding: 7px 0px;
  z-index: 10;

  &.fixed-menu {
    position: fixed;
  }
  
  .container-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .logo {
    background: url('/images/logo.svg') no-repeat left;
    background-size: contain;
    height: 42px;
    width: 159px;
    display: inline-block;
  }

  .menu-mobile-button {
    border: 1px solid #a57d73;
    border-radius: 3px;
    padding: 10px;
    height: 38px;
    background: transparent;

    display: flex;
    align-items: center;

    span {
      font: italic bold 18px 'Times New Roman';
      color: #a57d73;
    }

    i.open-menu {
      margin-left: 10px;
      display: inline-block;
      background: url('/images/hamb@2x.png') no-repeat center;
      background-size: contain;
      width: 24px;
      height: 15px;
    }
    i.close-menu {
      margin-left: 10px;
      display: inline-block;
      background: url('/images/close-menu.png') no-repeat center;
      background-size: contain;
      width: 17px;
      height: 17px;
    }
  }

  .menu-mobile {
    background: #2D2D2C;
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 56px);
    padding: 20px 20px 36px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    form {
      background: rgba(0, 0, 0, 0.2);
      height: 76px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        border: 0;
        height: 76px;
        padding: 0 0 0 20px;
        width: 80%;
        background: none;
        box-sizing: border-box;
        font: italic bold 20px 'Times new roman';
        color: #ffffff;
        
        &::placeholder {
          color: #a57d73;
        }
      }

      button {
        background: none;
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        padding: 0;
        i {
          background: url('/images/streamline-icon-search-1-48-x-48@2x.png') no-repeat center;
          background-size: contain;
          width: 24px;
          height: 24px;
          display: block;
        }
      }
    }

    ul {
      text-align: center;

      li a {
        font: italic bold 24px 'Times new roman';
        color: #a57d73;
        padding: 18px 0;
        display: block;
      }
    }

    .menu-mobile-footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .login-button {
        border: 1px solid #a57d73;
        border-radius: 3px;
        padding: 10px;
        height: 48px;
        width: 150px;
        background: transparent;
        font: italic bold 24px 'Times New Roman';
        color: #a57d73;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .register-button {
        margin-top: 26px;
        color: #a57d73;
        font-size: 14px;
        letter-spacing: 0.32px;
      }

      .loggout {
        margin-top: 30px;
        color: #a57d73;
        font-size: 14px;
        letter-spacing: 0.32px;
        background: transparent;
      }
    }

    .logged {
      display: flex;
      align-items: center;
      margin-left: auto;

      .perfil-button {
        margin-right: 30px;

        img {
          width: 76px;
          height: 76px;
          border-radius: 50%;
        }

        &:hover {
          opacity: .6;
        }

        .fakeAvatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #a57d73;
  
          i {
            background: url('https://guararapes-meuambiente.s3.sa-east-1.amazonaws.com/icon-profile.png') no-repeat center;
            background-size: contain;
            width: 27px;
            height: 21px;
            display: block;
          }
        }

      }

      .upload-button {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #a57d73;

        display: flex;
        align-items: center;
        justify-content: center;

        i {
          background: url('/images/icon-upload.svg') no-repeat center;
          background-size: contain;
          width: 27px;
          height: 21px;
          display: block;
        }

        &:hover {
          opacity: .6;
        }
      }
    }
  }

  .menu-desktop {
    display: none;
  }

  .menu-mobile-top-bar {
    background-color: #000;
    width: 100%;
    height: 185px;
    padding-top: 31px;
    margin-bottom: 10px;
  }

  .menu-mobile-top-bar ul li a {
    display: block;
    font-family: Ubuntu,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #fff;
    opacity: .8;
    padding: 9px 0;
  }

  .menu-mobile-top-bar ul li a.link-selected {
    color: #ffccbf;
    font-weight: 700;
  }

  @media (min-width: 767px) {    
     padding: 0px !important;
     
    .menu-mobile-button {
      display: none;
    }

    nav {
      flex: 1;
      margin-left: 25px;
    }

    .menu-desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;

      form {
        background: rgba(0, 0, 0, 0.2);
        height: 63px;
        width: 100%;
        max-width: 215px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
          border: 0;
          height: 58px;
          padding: 0 0 0 20px;
          width: 80%;
          background: none;
          box-sizing: border-box;
          font: italic bold 16px 'Times new roman';
          color: #ffffff;
          
          &::placeholder {
            color: #a57d73;
          }
        }

        button {
          background: none;
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          padding: 0;
          i {
            background: url('/images/streamline-icon-search-1-48-x-48@2x.png') no-repeat center;
            background-size: contain;
            width: 22px;
            height: 22px;
            display: block;
          }
        }
      }

      ul {
        display: flex;
        align-items: center;

        li a {
          font: italic bold 16px 'Times new roman';
          color: #a57d73;
          padding: 0 15px;

          display: flex;
          align-items: center;

          height: 58px;

          transition: .2s all ease;

          &:hover {
            color: #ffffff;
          }
        }
      }

      .login-button {
        border: 1px solid #a57d73;
        border-radius: 3px;
        padding: 10px;
        height: 38px;
        width: 70px;
        background: transparent;
        font: italic bold 16px 'Times New Roman';
        color: #a57d73;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: .2s all ease;

        &:hover {
          border: 1px solid #ffffff;
          color: #ffffff;
        }
      }

      .logged {
        display: flex;
        align-items: center;
        margin-left: auto;

        .perfil-button {
          margin-right: 25px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }

          &:hover {
            opacity: .6;
          }
        }



      .fakeAvatar {
        width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #a57d73;

          display: flex;
          align-items: center;
          justify-content: center;

          i {
            background: url('https://guararapes-meuambiente.s3.sa-east-1.amazonaws.com/icon-profile.png') no-repeat center;
            background-size: contain;
            width: 27px;
            height: 21px;
            display: block;
          }
      }

        .upload-button {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #a57d73;

          display: flex;
          align-items: center;
          justify-content: center;

          i {
            background: url('/images/icon_upload.svg') no-repeat center;
            background-size: contain;
            width: 27px;
            height: 21px;
            display: block;
          }

          &:hover {
            opacity: .6;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    padding: 0px !important;
    nav {
      margin-left: 100px;
    }

    .logo {
      height: 60px;
      width: 226px;
    }

    .menu-desktop {
      form {
        height: 83px;
        max-width: 305px;

        input {
          height: 78px;
          font: italic bold 20px 'Times new roman';
        }

        button i {
          width: 24px;
          height: 24px;
        }
      }

      ul {
        display: flex;
        align-items: center;
        margin-left: 15px;

        li a {
          font: italic bold 20px 'Times new roman';
          padding: 0 35px;

          height: 78px;
        }
      }

      .login-button {
        width: 85px;
        font: italic bold 20px 'Times New Roman';
        margin-left: auto;
      }
    }
  }
`;

export default function Menu(props) {
  const { isLoggedIn, singOut, getUser } = useContext(AuthContext);

  const [menuMobileOpen, setMenuMobileOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [fixedMenu, setFixedMenu] = useState(false);

  const handleScroll = () => {
    if (document.body.clientWidth < 768 && window.scrollY > 0) {
      setFixedMenu(true);
    } else if (document.body.clientWidth > 767 && window.scrollY > 48) {
      setFixedMenu(true);
    } else {
      setFixedMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props]);

  function toggleMenu() {
    setMenuMobileOpen(!menuMobileOpen);
  }

  function handleSearchSubmit(event) {
    event.preventDefault();
    window.location.href = `/busca/?s=${searchTerm}`;
  }

  function handleLogout() {
    singOut(() => window.location = window.location.origin);
  }

  return (
    <Container className={fixedMenu ? 'fixed-menu' : ''}>
      <ContainerDefault>
        <div className="container-menu">
          <Link to="/" className="logo"></Link>

          <nav>
            <button onClick={toggleMenu} className="menu-mobile-button">
              <span>{menuMobileOpen ? 'Fechar' : 'Menu'}</span>
              {menuMobileOpen ? (<i className="close-menu"></i>) : <i className="open-menu"></i>}
            </button>

            {menuMobileOpen && (
              <div className="menu-mobile">
                <form onSubmit={handleSearchSubmit}>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    placeholder="Digite sua busca"
                  />

                  <button type="submit">
                    <i></i>
                  </button>
                </form>

                <ul>
                  <li>
                    <Link to="/ambientes">Ambientes</Link>
                  </li>
                  <li>
                    <Link to="/profissionais">Profissionais</Link>
                  </li>
                  <li>
                    <Link to="/termos-de-utilizacao">Termos</Link>
                  </li>
                  <li>
                    <Link to="/contato">Contato</Link>
                  </li>
                </ul>

                <div className="menu-mobile-top-bar">
                <ul>
                  <li>
                    <a href="https://www.guararapes.com.br/" target='_blank'>Institucional</a>
                  </li>
                  <li>
                    <a href="https://casa.guararapes.com.br/" target='_blank'>Casa Guararapes</a>
                  </li>
                  <li>
                    <a href="/" class="link-selected">Meu Ambiente Guararapes</a>
                  </li>
                  <li>
                    <a href="https://www.guararapes.com.br/conteudos/blog" target='_blank'>Blog</a>
                  </li>
                </ul>
                </div>

                <div className="menu-mobile-footer">
                  {isLoggedIn ? (
                    <>
                      <div className="logged">
                        <Link to="/meu-perfil" className="perfil-button">
                          {getUser.company_avatar ? (
                            <img src={getUser.company_avatar} alt="Author name"/>
                            ) : (
                            <div className="fakeAvatar"><i></i></div>
                          )}
                        </Link>
                        <Link
                          to="/criar-ambiente"
                          state={{
                            modal: true
                          }}
                          className="upload-button"
                        >
                          <i></i>
                        </Link>
                      </div>
                      <button onClick={handleLogout} className="loggout">Deslogar</button>
                    </>
                  ) : (
                    <>
                      <Link
                        to="/login/"
                        state={{
                          modal: true
                        }}
                        className="login-button"
                      >
                        Login
                      </Link>
                      <Link to="/registrar" className="register-button">Ainda não tem cadastro? Crie aqui.</Link>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="menu-desktop">
              <form onSubmit={handleSearchSubmit}>
                <input
                  type="text"
                  alue={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  placeholder="Digite sua busca"
                />

                <button type="submit">
                  <i></i>
                </button>
              </form>

              <ul>
                <li>
                  <Link to="/ambientes">Ambientes</Link>
                </li>
                <li>
                  <Link to="/profissionais">Profissionais</Link>
                </li>
                <li>
                  <Link to="/termos-de-utilizacao">Termos</Link>
                </li>
              </ul>

              {isLoggedIn ? (
                <div className="logged">
                  <Link to="/meu-perfil" className="perfil-button">
                    {(getUser && getUser.company_avatar) ? (
                      <img src={getUser.company_avatar} alt="Author name"/>
                      ) : (
                      <div className="fakeAvatar"><i></i></div>
                    )}
                  </Link>
                  <Link
                    to="/criar-ambiente"
                    state={{
                      modal: true
                    }}
                    className="upload-button"
                  >
                    <i></i>
                  </Link>
                </div>
              ) : (
                <Link
                  to="/login/"
                  state={{
                    modal: true
                  }}
                  className="login-button"
                >
                  Login
                </Link>
              )}
            </div>
          </nav>
        </div>
      </ContainerDefault>
    </Container>
  );
}
