import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Social from './social';
import ContainerDefault from '../container';

const Container = styled.div`
  padding: 36px 0 25px;
  .wrapper {
    position: relative;
    &.flex {
      display: flex;
      align-items: center;
      height: 100%;
    }
    &:after {
      content: ' ';
      height: 1px;
      background: #979797;
      left: 15px;
      right: 15px;
      position: absolute;
      bottom: -25px;
      width: 100%;
      left: 0;
      @media (max-width: 767px) {
        display: none;
      }
    }
    .item {
      display: flex;
      &:first-child {
        flex-grow: 1;
      }
      @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        margin: 0 0 37px;
      }
    }
    .logo {
      background: url(/images/logo-negativa.svg) no-repeat left;
      background-size: contain;
      height: 56px;
      width: 190px;
      display: inline-block;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  @media (max-width: 767px) {
    padding: 36px 0 85px;
  }
`

export default function TopBar() {
  return (
    <Container>
      <ContainerDefault>
        <div className="wrapper flex">
          <div className="item">
            <Link to="/" className="logo"></Link>
          </div>
          <div className="item">
            <Social />
          </div>
        </div>
      </ContainerDefault>
    </Container>
  )
}
